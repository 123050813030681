import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="pt-8 px-24 flex flex-col">
      <h2 className="text-4xl w-full text-center">Terms of Service</h2>
      <p>Last Updated: 20-3-2024</p>

        <p className="text-2xl w-full text-center">IMPORTANT: by using this website, you are agreeing to be bound by the YouTube Terms of Service. (https://www.youtube.com/t/terms)</p>

      <p>
        This document is an agreement between you (referred to as "User" or "You") and Unsuuber (referred to as "we," "us," or "our") regarding your use of the Unsuuber web application (referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms of Service.
      </p>
      <h3>Acceptance of Terms</h3>
      <p>
        By accessing or using the Service, you agree to be bound by these Terms of Service and any additional terms and conditions that may apply to specific features of the Service. If you do not agree to these terms, you may not use the Service.
      </p>
      <h3>Use of the Service</h3>
      <p>
        2.1. You must be at least 13 years old to use the Service.
        <br />
        2.2. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
        <br />
        2.3. You agree not to use the Service for any illegal or unauthorized purpose.
      </p>
      <h3>Privacy Policy</h3>
      <p>Your use of the Service is also governed by our Privacy Policy, which is incorporated herein by reference. Please review the Privacy Policy <Link className=' text-purple-700 underline' to="/privacy">here</Link> for information on how we collect, use, and disclose your personal information.</p>
      <h3>YouTube API Compliance</h3>
      <p>
        4.1. The Service utilizes the YouTube Data API to provide its functionality.
        <br />
        4.2. By using the Service, you agree to comply with the YouTube API Services Terms of Service (https://developers.google.com/youtube/terms/api-services-terms-of-service).
        <br />
        4.3. You agree to use the API in accordance with the YouTube API Services Developer Policies (https://developers.google.com/youtube/terms/developer-policies).
        <br />
        4.4. You understand and acknowledge that your use of the API may be subject to additional limitations and restrictions imposed by YouTube, and you agree to abide by such limitations and restrictions.
      </p>
      <h3>Disclaimer of Warranties</h3>
      <p>
        5.1. The Service is provided "as is" and "as available" without warranties of any kind, either express or implied.
        <br />
        5.2. We do not warrant that the Service will be uninterrupted, secure, or error-free.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        6.1. In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the Service.
      </p>
      <h3>Indemnification</h3>
      <p>
        You agree to indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your use of the Service or your violation of these Terms of Service.
      </p>
      <h3>Changes to Terms</h3>
      <p>We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting the revised terms on the Service. Your continued use of the Service after the posting of the revised terms constitutes your acceptance of the changes.</p>
      <h3>Governing Law</h3>
      <p>These Terms of Service shall be governed by and construed in accordance with the laws of Hong Kong Special Administrative Region of the People's Republic of China.</p>
      <h3>Contact Information</h3>
      <p>If you have any questions about these Terms of Service, please contact us at unsuuber@protonmail.com .</p>
      <p>By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
    </div>
  );
};

export default TermsOfService;
