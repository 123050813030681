import { useEffect, useRef, useState } from "react";
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import { useSwipeable } from "react-swipeable";
import { Button } from "./ui/button";
import { unEscape } from "../App";
import { supabase } from "../lib/utils";

const DemoSwipe = () => {
  const [channels, setChannels] = useState<Array<any>>([]);
  const [listIndex, setListIndex] = useState<number>(0);

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: (e) => {
      console.log("swiped left");
      setListIndex((prev) => prev + 1);
    },
    onSwipedRight: (e) => {
      console.log("swiped right");
      setListIndex((prev) => prev + 1);
    },
  });
  async function getFeaturedChannel(channelId: string) {
    const { data, error } = await supabase.functions.invoke("get-sub-details", {
      body: { channelId: channelId },
    });
    return data;
  }

  useEffect(() => {
    async function foo() {
      const ting = await Promise.all([
        getFeaturedChannel("UC-lHJZR3Gqxm24_Vd_AJ5Yw"),
        getFeaturedChannel("UCX6OQ3DkcsbYNE6H8uQQuVA"),
        getFeaturedChannel("UCBJycsmduvYEL83R_U4JriQ"),
      ]);
      console.log(ting);
      setChannels(ting);
    }
    foo();
  }, []);
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "q" || e.key === "Q") {
      if (window.innerWidth > 1300) {
        qref.current?.classList.remove("pb-12");
        qref.current?.classList.add("pb-4");
        qref.current?.classList.add("bg-zinc-200");
      }
    }
    if (e.key === "e" || e.key === "E") {
      if (window.innerWidth > 1300) {
        eref.current?.classList.remove("pb-12");
        eref.current?.classList.add("pb-4");
        eref.current?.classList.add("bg-zinc-200");
      }
    }
  }
  function handleKeyUp(e: KeyboardEvent) {
    if (e.key === "q" || e.key === "Q") {
      qref.current?.classList.remove("pb-4");
      setListIndex((prev) => prev + 1);
      qref.current?.classList.add("pb-12");
      qref.current?.classList.remove("bg-zinc-200");
    }
    if (e.key === "e" || e.key === "E") {
      eref.current?.classList.remove("pb-4");
      setListIndex((prev) => prev + 1);
      eref.current?.classList.add("pb-12");
      eref.current?.classList.remove("bg-zinc-200");
    }
  }
  const qref = useRef<HTMLElement>(null);
  const eref = useRef<HTMLElement>(null);
  function channelImage() {
    if (listIndex === 0) {
      return "https://yt3.googleusercontent.com/5oUY3tashyxfqsjO5SGhjT4dus8FkN9CsAHwXWISFrdPYii1FudD4ICtLfuCw6-THJsJbgoY=s160-c-k-c0x00ffffff-no-rj";
    } else if (listIndex === 1) {
      return "https://yt3.googleusercontent.com/fxGKYucJAVme-Yz4fsdCroCFCrANWqw0ql4GYuvx8Uq4l_euNJHgE-w9MTkLQA805vWCi-kE0g=s160-c-k-c0x00ffffff-no-rj";
    } else if (listIndex === 2) {
      return "https://yt3.googleusercontent.com/lkH37D712tiyphnu0Id0D5MwwQ7IRuwgQLVD05iMXlDWO-kDHut3uI4MgIEAQ9StK0qOST7fiA=s160-c-k-c0x00ffffff-no-rj";
    }
  }
  useEffect(() => {
    if (listIndex > 2) {
      setListIndex(0);
    }
  }, [listIndex]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (channels[listIndex]?.response.error) {
    return <></>;
  }

  return (
    <div className="flex flex-row w-full justify-evenly" {...handlers}>
      <Card className="self-start w-[20%] max-w-[20rem] flex max-[1300px]:hidden flex-col items-center text-md break-words text-center">
        <CardHeader>Press Q to keep this subscription.</CardHeader>
        <CardContent>
          <kbd
            ref={qref}
            className="kbd font-keyboard text-8xl pb-12 border-4 w-[12rem] max-[1300px]:w-[8rem] max-[1300px]:h-[8rem] h-[12rem] "
          >
            Q
          </kbd>
        </CardContent>
      </Card>
      <Card className="w-[50%] max-[768px]:w-full self-center flex flex-col h-[600px]">
        <CardHeader className="flex flex-row items-center justify-evenly max-[768px]:justify-between h-[150px]">
          <h2 className=" text-4xl max-[768px]:text-2xl">
            {channels[listIndex]?.response.items[0]?.snippet.channelTitle}
          </h2>

          <img
            alt={channels[listIndex]?.response.items[0]?.snippet.channelTitle}
            src={channelImage()}
            className="h-[75px] w-[75px] max-[768px]:h-[50px] max-[768px]:w-[50px] rounded-full"
          />
        </CardHeader>
        <CardContent className="text-center overflow-x-scroll overflow-y-hidden break-words h-[450px] text-slate-600 dark:text-zinc-300 text-xl">
          <div className="flex flex-col gap-4 items-center">
            <h3 className="block text-ellipsis break-words overflow-hidden max-h-[3.2rem] h-[3.2rem] leading-[1.6rem]">
              {channels[listIndex]?.response.items[0]?.snippet.description}
            </h3>
            <h3 className="text-[24px] text-black">Latest Videos:</h3>
            {channels[listIndex]?.response.items?.map((vid: any) => (
              <div className="flex flex-row w-[80%] max-[768px]:w-full items-center justify-between gap-2">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="w-[70%] underline decoration-2 text-[20px] block text-ellipsis break-words overflow-hidden max-h-[3.6rem] h-[3.6rem] leading-[1.2rem] text-black text-start"
                  href={`https://www.youtube.com/watch?v=${vid.id.videoId}`}
                >
                  {unEscape(vid.snippet.title)}
                </a>
                <a
                  href={`https://www.youtube.com/watch?v=${vid.id.videoId}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    className="rounded-[16px]"
                    src={vid.snippet.thumbnails.high.url}
                    width={110}
                    height={90}
                  />
                </a>
              </div>
            ))}
          </div>
        </CardContent>
        <CardFooter className="flex flex-row self-center gap-4 pt-4">
          <Button
            variant="secondary"
            className="rounded-lg border-primary"
            onClick={() => {
              setListIndex((prev) => prev + 1);
            }}
          >
            keep
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              setListIndex((prev) => prev + 1);
            }}
          >
            unsuub
          </Button>
        </CardFooter>
      </Card>
      <Card className="max-w-[20rem] w-[20%] self-start flex flex-col items-center max-[1300px]:hidden text-md break-words text-center">
        <CardHeader>Press E to remove this subscription.</CardHeader>
        <CardContent>
          <kbd
            ref={eref}
            className="kbd font-keyboard text-8xl pb-12 border-4 w-[8rem] h-[8rem] max-[1300px]:hidden "
          >
            E
          </kbd>
        </CardContent>
      </Card>
    </div>
  );
};

export default DemoSwipe;
