import { createClient } from "@supabase/supabase-js";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Database } from "../types/supabase";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
const supabase = createClient<Database>(
  "https://pnfwxbhrovaicdljhfcj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBuZnd4Ymhyb3ZhaWNkbGpoZmNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzcwOTg0ODMsImV4cCI6MjA1MjY3NDQ4M30.E4W6V3bN1zc5OgTGvv-OxzzMYXsbm4a0WUregSOUqFw"
);

export { supabase };
