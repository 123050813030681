import React, { useEffect, useRef, useState } from "react";
import { TokenResponse } from "@react-oauth/google";
import "./App.css";
import { SubscriptionItem, SubscriptionListResponse } from "./types/sub";
import { Button } from "./components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "./components/ui/card";
import { supabase } from "./lib/utils";
import { useNavigate } from "react-router-dom";
import { Coffee, LogOut } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import Click from "./Click";
import { useSwipeable } from "react-swipeable";
import "./index.css";
enum SwipeActionType {
  UNSUB = "unsub",
  NEXT = "next",
}

function App() {
  const [creds, setCreds] = useState<
    Omit<TokenResponse, "error" | "error_description" | "error_uri"> | string
  >();
  const [subRes, setSubRes] = useState<SubscriptionListResponse>();
  const [sublist, setSublist] = useState<Array<SubscriptionItem>>([]);
  const [listIndex, setListIndex] = useState<number>(0);
  const [userSessionId, setUserSessionId] = useState<string>();
  const [clickedStartSwiping, setClickedStartSwiping] = useState(false);
  const [lastCall, setLastCall] = useState<number>();
  const [latestVideosOfChannel, setLatestVideosOfChannel] = useState<any[]>([]);
  const navigate = useNavigate();
  const qref = useRef<HTMLElement>(null);
  const eref = useRef<HTMLElement>(null);
  const mainDivRef = useRef<HTMLBodyElement>(null);

  async function getNextPageOfSubs() {
    console.log("subres:", subRes);
    if (!subRes) {
      throw new Error("I cant think rn");
    }
    const { data, error } = await supabase.functions.invoke(
      "get-subscription",
      {
        body: {
          accessToken: creds,
          type: "nextPage",
          nextPageToken: subRes.nextPageToken,
        },
      }
    );
    if (error || data.response.items === undefined) {
      return;
    }

    console.log(data);
    setSubRes(data.response);
    const newArr = [...sublist, ...data.response.items];
    setSublist(newArr);
  }

  async function getLatestVidsOfAChannel() {
    if (sublist.length <= 0) {
      return;
    }
    if (sublist[listIndex] === undefined) {
      return;
    }
    const { data, error } = await supabase.functions.invoke("get-sub-details", {
      body: { channelId: sublist[listIndex].snippet.resourceId.channelId },
    });
    if (error) {
      return;
    }
    setLatestVideosOfChannel(data.response.items);
    console.log("subDetails:", data);
  }

  useEffect(() => {
    async function checkUserIsCreated() {
      if (creds) {
        return;
      }
      const { data, error } = await supabase.auth.getUser();
      const authProviderTokenFromLocalStorage = window.localStorage.getItem(
        "oauth_provider_token"
      );

      if (!authProviderTokenFromLocalStorage) {
        navigate("/");
      }
      setCreds(authProviderTokenFromLocalStorage!);

      if (data.user === null || error) {
        navigate("/");
        return;
      }
      setUserSessionId(data!.user!.id);
    }
    checkUserIsCreated();
    mainDivRef.current?.focus();
  }, []);

  useEffect(() => {
    async function getFirstSubs() {
      if (creds) {
        const { data, error } = await supabase.functions.invoke(
          "get-subscription",
          {
            body: {
              accessToken: creds,
              type: "firstPage",
            },
          }
        );
        if (data.response.error) {
          await supabase.auth.signOut();
        }
        console.log(data);
        setSubRes(data.response);
        setSublist(data.response.items);
      }
    }
    getFirstSubs();
  }, [creds]);

  useEffect(() => {
    if (sublist.length === 5 && listIndex === 0) {
      getLatestVidsOfAChannel();
    }
  }, [sublist]);

  async function getNextSubscription(type: string) {
    console.log("sublist", sublist);
    console.log("index", listIndex);
    console.log("sublistindex", sublist[listIndex]);
    if (lastCall === undefined) {
      if (type === SwipeActionType.UNSUB) {
        const response = await supabase.functions.invoke("swipe", {
          body: {
            userId: userSessionId,
            subscriptionId: sublist[listIndex].id,
            accessToken: creds,
          },
        });
        console.log("swipeResponse:", response);
        try {
          setLatestVideosOfChannel([]);
          setListIndex((prev) => prev + 1);
        } catch (error) {
          console.log(error);
        }
      } else {
        setLatestVideosOfChannel([]);
        setListIndex((prev) => prev + 1);
      }
      setLastCall(Date.now());
    } else {
      if (lastCall + 1000 < Date.now()) {
        if (type === SwipeActionType.UNSUB) {
          try {
            const { data, error } = await supabase.functions.invoke("swipe", {
              body: {
                userId: userSessionId,
                subscriptionId: sublist[listIndex].id,
                accessToken: creds,
              },
            });
            if (error) {
              throw error;
            }
            setLatestVideosOfChannel([]);
            setListIndex((prev) => prev + 1);
          } catch (error) {
            console.log(error);
          }
        } else {
          setLatestVideosOfChannel([]);
          setListIndex((prev) => prev + 1);
        }
        setLastCall(Date.now());
      }
    }
  }
  useEffect(() => {
    getLatestVidsOfAChannel();
    if (listIndex + 2 === sublist.length) {
      getNextPageOfSubs();
    }
  }, [listIndex]);

  function handleKeyDown(e: any) {
    if (e.key === "q" || e.key === "Q") {
      if (window.innerWidth > 1300) {
        qref.current?.classList.remove("pb-12");
        qref.current?.classList.add("pb-4");
        qref.current?.classList.add("bg-zinc-200");
      }
    }
    if (e.key === "e" || e.key === "E") {
      if (window.innerWidth > 1300) {
        eref.current?.classList.remove("pb-12");
        eref.current?.classList.add("pb-4");
        eref.current?.classList.add("bg-zinc-200");
      }
    }
  }
  function handleKeyUp(e: any) {
    if (e.key === "q" || e.key === "Q") {
      qref.current?.classList.remove("pb-4");
      getNextSubscription(SwipeActionType.NEXT);
      qref.current?.classList.add("pb-12");
      qref.current?.classList.remove("bg-zinc-200");
    }
    if (e.key === "e" || e.key === "E") {
      eref.current?.classList.remove("pb-4");
      getNextSubscription(SwipeActionType.UNSUB);
      eref.current?.classList.add("pb-12");
      eref.current?.classList.remove("bg-zinc-200");
    }
  }
  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: (e) => {
      console.log("swiped left");
      getNextSubscription(SwipeActionType.NEXT);
    },
    onSwipedRight: (e) => {
      console.log("swiped right");
      getNextSubscription(SwipeActionType.UNSUB);
    },
  });

  return (
    <body
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      ref={mainDivRef}
      className="flex flex-col overflow-x-hidden dark:bg-zinc-950 min-h-[100dvh] max-h-[100dvh] justify-evenly max-w-[100dvw] gap-4 dark:text-white"
    >
      <nav className="self-center pt-4 flex flex-row gap-4 justify-between  w-[50%] max-[768px]:w-[80%] items-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="destructive">
              <LogOut className="h-[2rem] w-[2rem] " />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="text-center bg-secondary border-slate-500  rounded-xl"
              onClick={async () => {
                const { error } = await supabase.auth.signOut();
                console.log(error);
                navigate("/");
              }}
            >
              <Button
                variant="ghost"
                className="w-full px-20 text-primary hover:text-orange-700 text-xl py-6"
              >
                Sign Out
              </Button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </nav>

      <div className="flex flex-col items-center gap-8 h-full justify-between">
        <div className="text-xl text-center break-words">
          <span className="">
            Start Swiping! There is a one second delay between each swipe.
          </span>
        </div>
        {sublist.length === 0 || sublist[listIndex] === undefined ? (
          <div className="flex flex-col items-center justify-center h-[600px]">
            <p className="">
              You don't have any more subscriptions on your account.
            </p>
          </div>
        ) : (
          <></>
        )}
        {sublist.length > 0 && sublist[listIndex] && (
          <div
            className="flex flex-row items-center w-full justify-evenly"
            {...handlers}
          >
            <Card
              className="self-start w-[20%] max-w-[20rem] flex max-[1300px]:hidden flex-col items-center text-md break-words text-center"
              autoFocus
            >
              <CardHeader>Press Q to keep this subscription.</CardHeader>
              <CardContent>
                <kbd
                  ref={qref}
                  className="kbd font-keyboard text-8xl pb-12 border-4 w-[12rem] max-[1300px]:w-[8rem] max-[1300px]:h-[8rem] h-[12rem] "
                >
                  Q
                </kbd>
              </CardContent>
            </Card>
            <Card
              className="w-[50%] max-[768px]:w-[90%] self-center flex flex-col h-[650px]"
              autoFocus
            >
              <CardHeader className="flex flex-row items-center justify-evenly h-[150px] p-0">
                <h2 className=" text-4xl mobile:text-2xl">
                  {sublist[listIndex].snippet.title}
                </h2>
                <img
                  alt={sublist[listIndex].snippet.title}
                  src={sublist[listIndex].snippet.thumbnails.default.url}
                  className="h-[75px] rounded-full"
                />
              </CardHeader>
              <CardContent className="text-center overflow-x-scroll overflow-y-hidden break-words h-[550px] text-slate-600 dark:text-zinc-300 text-xl">
                <div className="flex flex-col gap-8 items-center justify-between">
                  <h3 className="block text-ellipsis break-words overflow-hidden max-h-[3.2rem] h-[3.2rem] leading-[1.6rem] w-[80%]">
                    {sublist[listIndex].snippet.description}
                  </h3>
                  <h3 className="text-[24px] text-black">Latest Videos:</h3>
                  {latestVideosOfChannel.map((vid) => (
                    <div className="flex flex-row w-[80%] max-[768px]:w-full items-center justify-between">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className="w-[70%] underline decoration-2 text-[20px] block text-ellipsis break-words overflow-hidden max-h-[3.6rem] h-[3.6rem] leading-[1.2rem] text-black text-start"
                        href={`https://www.youtube.com/watch?v=${vid.id.videoId}`}
                      >
                        {unEscape(vid.snippet.title)}
                      </a>
                      <a
                        href={`https://www.youtube.com/watch?v=${vid.id.videoId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className="rounded-[16px]"
                          src={vid.snippet.thumbnails.high.url}
                          width={100}
                          height={80}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </CardContent>
              <CardFooter className="flex flex-row self-center gap-4 pt-4">
                <Button
                  variant="secondary"
                  className="rounded-lg border-primary"
                  onClick={() => {
                    getNextSubscription(SwipeActionType.NEXT);
                  }}
                >
                  keep
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => {
                    getNextSubscription(SwipeActionType.UNSUB);
                  }}
                >
                  unsuub
                </Button>
              </CardFooter>
            </Card>
            <Card
              className="max-w-[20rem] w-[20%] self-start flex flex-col items-center max-[1300px]:hidden text-md break-words text-center"
              autoFocus
            >
              <CardHeader>Press E to remove this subscription.</CardHeader>
              <CardContent>
                <kbd
                  ref={eref}
                  className="kbd font-keyboard text-8xl pb-12 border-4 w-[8rem] h-[8rem] max-[1300px]:hidden "
                >
                  E
                </kbd>
              </CardContent>
            </Card>
          </div>
        )}
      </div>

      <footer className="mt-auto pb-6 border-t-slate-300 text-xl text-center flex flex-col items-center">
        <a
          href="https://ko-fi.com/unsuuber"
          target="_blank"
          rel="noreferrer"
          className="flex flex-row items-center gap-2"
        >
          Buy us a coffee <Coffee />
        </a>
      </footer>
    </body>
  );
}

export default App;

export function unEscape(htmlStr: string) {
  htmlStr = htmlStr.replace(/&lt;/g, "<");
  htmlStr = htmlStr.replace(/&gt;/g, ">");
  htmlStr = htmlStr.replace(/&quot;/g, '"');
  htmlStr = htmlStr.replace(/&#39;/g, "'");
  htmlStr = htmlStr.replace(/&amp;/g, "&");
  return htmlStr;
}
